import React from 'react'
import { P } from '@farewill/ui'
import { RoleSection, RoleTemplate, RoleTitle, RoleUl } from '../RoleTemplate'

const CustomerExperienceSpecialist = (): React.JSX.Element => (
  <RoleTemplate
    roleName="Customer Experience Specialist"
    roleUrl="https://dignity.kallidusrecruit.com/VacancyInformation.aspx?VId=2131"
    location="Haggerston, London (Hybrid flexible working, 3 days in the office)"
    salary="£30,000"
    contractType="Full-time, Permanent"
    closingDate="30th April 2025"
    content={
      <>
        <RoleSection>
          <RoleTitle>The Role</RoleTitle>
          <P>
            At Farewill, we help people navigate some of life’s most challenging
            moments with kindness, expertise, and care. Now part of Dignity
            Funerals, we’re continuing our mission to make after-life services
            simpler, more affordable, and accessible for everyone.
          </P>
          <P>
            As a Customer Experience Specialist, you’ll be the face of Farewill,
            delivering exceptional service over phone and email—helping
            customers with their wills, funerals and estate administration. If
            you’re a compassionate problem-solver with a passion for helping
            others, we’d love to hear from you.
          </P>
        </RoleSection>
        <RoleSection>
          <RoleTitle>What the day-to-day will look like for you:</RoleTitle>
          <RoleUl>
            <li>
              Providing friendly, confident support over phone and email,
              ensuring a smooth customer journey.
            </li>
            <li>
              Becoming an expert in our services, from will-writing to funeral
              planning, and helping customers navigate their options.
            </li>
            <li>
              Reviewing wills to ensure they accurately reflect customers’
              wishes.
            </li>
            <li>
              Troubleshooting issues independently and escalating where needed.
            </li>
            <li>
              Identifying opportunities to improve processes and make our
              services even better.
            </li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>We are looking for someone who is:</RoleTitle>
          <RoleUl>
            <li>
              Passionate for customer service – you know what makes a great
              customer experience and love delivering it.
            </li>
            <li>
              A problem-solving mindset – you enjoy cracking challenges and
              getting the details right.
            </li>
            <li>
              Strong communicator – you can explain complex information simply
              and clearly.
            </li>
            <li>
              Empathetic and caring – friends and colleagues describe you as
              someone who genuinely wants to help others.
            </li>
            <li>
              Has experience in a fast-paced customer-facing role, ideally in a
              startup, funerals, healthcare, or charities, although not
              essential.
            </li>
            <li>
              Adaptable and flexible, happy to support other areas of the
              business when needed.
            </li>
            <li>
              Tech-savvy – understands systems quickly and has some experience
              with Zendesk, Aircall, or similar tools (this is not essential).
            </li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>What we offer</RoleTitle>
          <RoleUl>
            <li>Annual salary of £30,000</li>
            <li>25 Days Holiday + Bank Holidays</li>
            <li>BUPA Healthcare (Self)</li>
            <li>X2 Life Assurance</li>
            <li>Pension Scheme</li>
            <li>Hybrid flexible working</li>
            <li>Access to our internal colleague development schemes</li>
          </RoleUl>
        </RoleSection>
      </>
    }
  />
)

export default CustomerExperienceSpecialist
